.form-field.error {
    @apply border-red-700 border-2 mb-2;
}

.form-error-container li {
    @apply mb-2;
}

.form-label.required:after,
.form-checkbox-label.required:after {
    @apply text-red-600;
    content: " *";
}

.form-error-container li,
.form-group .form-label.error {
    @apply text-sm font-medium text-red-700 dark:text-red-500;
}

.form-error-container li {
    @apply text-xs;
}
