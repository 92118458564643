@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .bg-theme-primary {
        background-color: var(--color-primary);
    }

    .navbar-bg-color {
        background-color: var(--navbar-bg-color, var(--color-primary));
    }

    .navbar-text-color {
        color: var(--navbar-text-color, var(--color-text-primary));
    }

    .hover\:bg-theme-primary-50:hover,
    .focus\:bg-theme-primary-50:focus,
    .bg-theme-primary-50 {
        background-color: rgba(var(--color-primary-50), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-100:hover,
    .focus\:bg-theme-primary-100:focus,
    .bg-theme-primary-100 {
        background-color: rgba(var(--color-primary-100), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-200:hover,
    .focus\:bg-theme-primary-200:focus,
    .bg-theme-primary-200 {
        background-color: rgba(var(--color-primary-200), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-300:hover,
    .focus\:bg-theme-primary-300:focus,
    .bg-theme-primary-300 {
        background-color: rgba(var(--color-primary-300), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-400:hover,
    .focus\:bg-theme-primary-400:focus,
    .bg-theme-primary-400 {
        background-color: rgba(var(--color-primary-400), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-500:hover,
    .focus\:bg-theme-primary-500:focus,
    .bg-theme-primary-500 {
        background-color: rgba(var(--color-primary-500), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-600:hover,
    .focus\:bg-theme-primary-600:focus,
    .bg-theme-primary-600 {
        background-color: rgba(var(--color-primary-600), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-700:hover,
    .focus\:bg-theme-primary-700:focus,
    .bg-theme-primary-700 {
        background-color: rgba(var(--color-primary-700), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-800:hover,
    .focus\:bg-theme-primary-800:focus,
    .bg-theme-primary-800 {
        background-color: rgba(var(--color-primary-800), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-theme-primary-900:hover,
    .focus\:bg-theme-primary-900:focus,
    .bg-theme-primary-900 {
        background-color: rgba(var(--color-primary-900), var(--tw-bg-opacity, 1));
    }

    .text-theme-primary {
        color: var(--color-primary);
    }

    .text-theme-secondary {
        color: var(--color-secondary);
    }

    .hover\:text-theme-secondary-50:hover,
    .focus\:text-theme-secondary-50:focus,
    .text-theme-secondary-50 {
        color: rgba(var(--color-secondary-50), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-100:hover,
    .focus\:text-theme-secondary-100:focus,
    .text-theme-secondary-100 {
        color: rgba(var(--color-secondary-100), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-200:hover,
    .focus\:text-theme-secondary-200:focus,
    .text-theme-secondary-200 {
        color: rgba(var(--color-secondary-200), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-300:hover,
    .focus\:text-theme-secondary-300:focus,
    .text-theme-secondary-300 {
        color: rgba(var(--color-secondary-300), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-400:hover,
    .focus\:text-theme-secondary-400:focus,
    .text-theme-secondary-400 {
        color: rgba(var(--color-secondary-400), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-500:hover,
    .focus\:text-theme-secondary-500:focus,
    .text-theme-secondary-500 {
        color: rgba(var(--color-secondary-500), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-600:hover,
    .focus\:text-theme-secondary-600:focus,
    .text-theme-secondary-600 {
        color: rgba(var(--color-secondary-600), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-700:hover,
    .focus\:text-theme-secondary-700:focus,
    .text-theme-secondary-700 {
        color: rgba(var(--color-secondary-700), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-800:hover,
    .focus\:text-theme-secondary-800:focus,
    .text-theme-secondary-800 {
        color: rgba(var(--color-secondary-800), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-secondary-900:hover,
    .focus\:text-theme-secondary-900:focus,
    .text-theme-secondary-900 {
        color: rgba(var(--color-secondary-900), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-50:hover,
    .focus\:text-theme-primary-50:focus,
    .text-theme-primary-50 {
        color: rgba(var(--color-text-primary-50), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-100:hover,
    .focus\:text-theme-primary-100:focus,
    .text-theme-primary-100 {
        color: rgba(var(--color-text-primary-100), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-200:hover,
    .focus\:text-theme-primary-200:focus,
    .text-theme-primary-200 {
        color: rgba(var(--color-text-primary-200), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-300:hover,
    .focus\:text-theme-primary-300:focus,
    .text-theme-primary-300 {
        color: rgba(var(--color-text-primary-300), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-400:hover,
    .focus\:text-theme-primary-400:focus,
    .text-theme-primary-400 {
        color: rgba(var(--color-text-primary-400), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-500:hover,
    .focus\:text-theme-primary-500:focus,
    .text-theme-primary-500 {
        color: rgba(var(--color-text-primary-500), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-600:hover,
    .focus\:text-theme-primary-600:focus,
    .text-theme-primary-600 {
        color: rgba(var(--color-text-primary-600), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-700:hover,
    .focus\:text-theme-primary-700:focus,
    .text-theme-primary-700 {
        color: rgba(var(--color-text-primary-700), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-800:hover,
    .focus\:text-theme-primary-800:focus,
    .text-theme-primary-800 {
        color: rgba(var(--color-text-primary-800), var(--tw-bg-opacity, 1));
    }

    .hover\:text-theme-primary-900:hover,
    .focus\:text-theme-primary-900:focus,
    .text-theme-primary-900 {
        color: rgba(var(--color-text-primary-900), var(--tw-bg-opacity, 1));
    }

    .bg-main {
        background-color: rgba(var(--color-background), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-50:hover,
    .focus\:bg-main-50:focus,
    .bg-main-50 {
        background-color: rgba(var(--color-background-50), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-100:hover,
    .focus\:bg-main-100:focus,
    .bg-main-100 {
        background-color: rgba(var(--color-background-100), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-200:hover,
    .focus\:bg-main-200:focus,
    .bg-main-200 {
        background-color: rgba(var(--color-background-200), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-300:hover,
    .focus\:bg-main-300:focus,
    .bg-main-300 {
        background-color: rgba(var(--color-background-300), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-400:hover,
    .focus\:bg-main-400:focus,
    .bg-main-400 {
        background-color: rgba(var(--color-background-400), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-500:hover,
    .focus\:bg-main-500:focus,
    .bg-main-500 {
        background-color: rgba(var(--color-background-500), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-600:hover,
    .focus\:bg-main-600:focus,
    .bg-main-600 {
        background-color: rgba(var(--color-background-600), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-700:hover,
    .focus\:bg-main-700:focus,
    .bg-main-700 {
        background-color: rgba(var(--color-background-700), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-800:hover,
    .focus\:bg-main-800:focus,
    .bg-main-800 {
        background-color: rgba(var(--color-background-800), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-main-900:hover,
    .focus\:bg-main-900:focus,
    .bg-main-900 {
        background-color: rgba(var(--color-background-900), var(--tw-bg-opacity, 1));
    }

    .bg-footer {
        background-color: var(--color-footer);
    }

    .hover\:bg-footer-50:hover,
    .focus\:bg-footer-50:focus,
    .bg-footer-50 {
        background-color: rgba(var(--color-footer-50), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-100:hover,
    .focus\:bg-footer-100:focus,
    .bg-footer-100 {
        background-color: rgba(var(--color-footer-100), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-200:hover,
    .focus\:bg-footer-200:focus,
    .bg-footer-200 {
        background-color: rgba(var(--color-footer-200), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-300:hover,
    .focus\:bg-footer-300:focus,
    .bg-footer-300 {
        background-color: rgba(var(--color-footer-300), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-400:hover,
    .focus\:bg-footer-400:focus,
    .bg-footer-400 {
        background-color: rgba(var(--color-footer-400), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-500:hover,
    .focus\:bg-footer-500:focus,
    .bg-footer-500 {
        background-color: rgba(var(--color-footer-500), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-600:hover,
    .focus\:bg-footer-600:focus,
    .bg-footer-600 {
        background-color: rgba(var(--color-footer-600), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-700:hover,
    .focus\:bg-footer-700:focus,
    .bg-footer-700 {
        background-color: rgba(var(--color-footer-700), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-800:hover,
    .focus\:bg-footer-800:focus,
    .bg-footer-800 {
        background-color: rgba(var(--color-footer-800), var(--tw-bg-opacity, 1));
    }

    .hover\:bg-footer-900:hover,
    .focus\:bg-footer-900:focus,
    .bg-footer-900 {
        background-color: rgba(var(--color-footer-900), var(--tw-bg-opacity, 1));
    }

    .text-on-footer {
        color: var(--color-text-footer);
    }

    .text-on-primary {
        color: var(--color-text-primary);
    }

    .text-text {
        color: var(--color-text);
    }

    .text-link {
        @apply text-theme-secondary;
    }

    .border-theme-secondary {
        border-color: var(--color-secondary);
    }

    .hover\:border-theme-secondary-50:hover,
    .focus\:border-theme-secondary-50:focus,
    .border-theme-secondary-50 {
        border-color: rgba(var(--color-secondary-50), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-100:hover,
    .focus\:border-theme-secondary-100:focus,
    .border-theme-secondary-100 {
        border-color: rgba(var(--color-secondary-100), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-200:hover,
    .focus\:border-theme-secondary-200:focus,
    .border-theme-secondary-200 {
        border-color: rgba(var(--color-secondary-200), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-300:hover,
    .focus\:border-theme-secondary-300:focus,
    .border-theme-secondary-300 {
        border-color: rgba(var(--color-secondary-300), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-400:hover,
    .focus\:border-theme-secondary-400:focus,
    .border-theme-secondary-400 {
        border-color: rgba(var(--color-secondary-400), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-500:hover,
    .focus\:border-theme-secondary-500:focus,
    .border-theme-secondary-500 {
        border-color: rgba(var(--color-secondary-500), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-600:hover,
    .focus\:border-theme-secondary-600:focus,
    .border-theme-secondary-600 {
        border-color: rgba(var(--color-secondary-600), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-700:hover,
    .focus\:border-theme-secondary-700:focus,
    .border-theme-secondary-700 {
        border-color: rgba(var(--color-secondary-700), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-800:hover,
    .focus\:border-theme-secondary-800:focus,
    .border-theme-secondary-800 {
        border-color: rgba(var(--color-secondary-800), var(--tw-bg-opacity, 1));
    }

    .hover\:border-theme-secondary-900:hover,
    .focus\:border-theme-secondary-900:focus,
    .border-theme-secondary-900 {
        border-color: rgba(var(--color-secondary-900), var(--tw-bg-opacity, 1));
    }

    .energy-label {
        clip-path: polygon(0 0, 70% 0, 100% 50%, 70% 100%, 0 100%);
    }
}

:root {
    --swiper-theme-color: var(--color-primary) !important;
}

a {
    @apply text-link;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply text-balance;
}

body {
    @apply bg-main text-text;
}

footer {
    @apply bg-footer text-on-footer;
}

.image {
    @apply flex justify-between flex-col;
}
.image > img {
    @apply mx-auto;
}
.image.stretch > img {
    @apply w-full;
}

.flash-message {
    @apply p-4 bg-teal-100 flex justify-center;
}
.flash-message.flash-message-success {
    @apply bg-emerald-100;
}

.form-label {
    @apply block text-sm font-medium text-gray-700 mb-2 w-full dark:text-gray-200;
}
.form-group {
    @apply mb-3;
}
.form-field {
    @apply block bg-gray-50 dark:bg-main w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm p-2.5 focus:border-indigo-500 focus:ring-indigo-500;
}
.form-field.disabled {
    @apply cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500;
}

.form-outer {
    @apply p-4 max-w-md;
}
.action-btn {
    @apply inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-theme-primary-700 hover:bg-theme-primary-800 focus:ring-4 focus:ring-primary-300;
}
.form-select {
    @apply cursor-pointer disabled:text-gray-500;
}

/* Used for HTML5 accordion */
details > summary::-webkit-details-marker {
    @apply hidden;
}

details[open] .rotate-0.transform {
    @apply rotate-180;
}

.swiper-wrapper .swiper-slide {
    @apply h-auto;
}
